<template lang="html">
  <div class="dungeon-map">
    <OrganPipesMap />

    <SelectSlider
      title="Werk:"
      :options="$store.getters['organ/currentPipeVariants'].areas"
      :defaultOption="$store.state.organ.selectedPipe"
      :hideArrows="true"
    />
    <SelectSlider
      title="Pfeife:"
      :options="$store.getters['organ/currentPipeVariants'].pipes"
      :defaultOption="$store.state.organ.selectedPipe"
      :counter="true"
      :hideArrows="true"
    />
    <div class="price">
      {{
        $store.getters['organ/currentPipeVariants'].prices[
          $store.state.organ.selectedPipe - 1
        ]
      }}
      €
    </div>
  </div>
  <div class="buttons-wrapper">
    <Button backgroundColor="#C99D66" @click="nextStep">
      <span v-if="globalSettings">
        {{ globalSettings['button_names']['select_area'] }}
      </span>
    </Button>

    <Button
      styleButton="border"
      @click="$store.commit('organ/showPipeVariantsScreen', false)"
    >
      <span>Zurück</span>
    </Button>
  </div>
</template>

<script>
import SelectSlider from '@/components/SelectSlider/SelectSlider';
import OrganPipesMap from '@/sections/Organ/OrganPipesMap.vue';
import Button from '@/components/Button/Button.vue';

export default {
  name: 'OrganPartsMobile',
  components: { Button, OrganPipesMap, SelectSlider },
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
  },
  methods: {
    nextStep() {
      this.$store.commit('organ/setFormStepsIsShow', true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
.buttons-wrapper {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.wall-info {
  &__container {
    border-radius: 6px;
  }

  &__image {
    height: 0;
    padding-bottom: calc(160 / 335 * 100%);
    position: relative;
    margin-bottom: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &__heading {
    font-size: 22px;
    color: #c99d66;
    text-align: center;
  }

  &__button {
    width: 197px;
    margin: 26px auto 0;
    &:deep {
      .button {
        border-radius: 0;
      }
    }
  }
}
.price {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  color: #c99d66;
}
:deep(.select-slider) {
  margin-bottom: 20px;
}
</style>
