<template lang="html">
  <div class="organ-parts areas">
    <div class="steps__heading" v-if="isMobile">
      <Heading :hType="3" text="Orgelpfeifenauswahl" textAlign="center" />
      <Heading :hType="1" text="Orgelpfeife" textAlign="center" />
    </div>
    <OrganPartsMobile v-if="isMobile" />
    <OrganPartsDesktop v-else />
  </div>
</template>

<script>
import Heading from '@/components/Heading/Heading';
import OrganPartsMobile from './OrganPartsMobile.vue';
import OrganPartsDesktop from './OrganPartsDesktop.vue';

export default {
  name: 'OrganParts',
  components: { OrganPartsDesktop, OrganPartsMobile, Heading },
  data() {
    return {
      selectedArea: {},
      selectedWall: '',
    };
  },
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
    isMobile() {
      return this.$store.state.global.isMobile;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
