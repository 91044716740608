<template lang="html">
  <div class="organ steps steps-area">
    <div class="steps__container">
      <template v-if="!organState.form.formStepsIsShow || !isMobile">
        <div class="steps__header" v-if="isMobile">
          <Back label="" color="#C99D66" :customBack="true" @click="prevStep" />
          <Steps
            :steps="organState.steps"
            :currentStep="organState.currentStep"
          />
        </div>
        <OrganParts v-if="organState.currentStep === 1" />
      </template>
      <Sidebar
        :isShow="organState.form.formStepsIsShow"
        @closeSidebar="closeSidebar"
      >
        <div class="stones-form">
          <div class="stones-form__columns">
            <div class="stones-form__column">
              <div
                class="stones-form-info"
                v-if="!isMobile && organState.form.currentStep !== 3"
              >
                <div class="stones-form-info__heading">
                  <Heading :h-type="1" text="OrgelPfeife" />
                </div>
                <div class="stones-form-info__description">
                  Adoptieren Sie eine Pfeife oder ein Register der
                  Frauenkirchenorgel mit einer Spende oder Zustiftung
                </div>

                <div v-if="pipeImage" class="pipe-image">
                  <img :src="pipeImage.url" :alt="pipeImage.alt" />
                </div>

                <div class="stones-form-info__columns">
                  <div class="stones-form-info__column">
                    <div class="stones-form-info__label">Werk</div>
                    <div class="stones-form-info__value">
                      {{
                        $store.getters['organ/currentPipeVariants'].areas[
                          $store.state.organ.selectedPipe - 1
                        ].label
                      }}
                    </div>
                  </div>
                </div>
                <div class="stones-form-info__columns">
                  <div class="stones-form-info__column">
                    <div class="stones-form-info__label">Pfeife</div>
                    <div
                      class="stones-form-info__value"
                      v-html="
                        $store.getters['organ/currentPipeVariants'].pipes[
                          $store.state.organ.selectedPipe - 1
                        ].label
                      "
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="stones-form__column">
              <div class="steps__header steps__header--steps">
                <Steps
                  :steps="organState.form.steps"
                  :currentStep="organState.form.currentStep"
                />
              </div>
              <OrganFormStep1 v-if="organState.form.currentStep === 1" />
              <OrganFormStep2 v-if="organState.form.currentStep === 2" />
              <OrganFormStep3 v-if="organState.form.currentStep === 3" />
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  </div>
</template>

<script>
import OrganParts from '@/sections/Organ/OrganParts';
import Steps from '@/components/Steps/Steps';
import Back from '@/components/Back/Back';
import OrganFormStep1 from '@/sections/Organ/OrganFormStep1';
import OrganFormStep2 from '@/sections/Organ/OrganFormStep2';
import OrganFormStep3 from '@/sections/Organ/OrganFormStep3';
import Sidebar from '@/components/Sidebar/Sidebar.vue';
import Heading from '@/components/Heading/Heading.vue';

export default {
  name: 'OrganPipeVariants',
  components: {
    Heading,
    OrganFormStep1,
    OrganFormStep2,
    OrganFormStep3,
    Back,
    OrganParts,
    Steps,
    Sidebar,
  },
  data() {
    return {
      changedData: {},
    };
  },
  computed: {
    organState() {
      return this.$store.state.organ;
    },
    isMobile() {
      return this.$store.state.global.isMobile;
    },
    donationPaymentStore() {
      return this.$store.state.donationPayment;
    },
    pipeImage() {
      if (
        this.$store.getters['organ/currentPipeVariants'].pipes_images[
          this.$store.state.organ.selectedPipe - 1
        ] &&
        this.$store.getters['organ/currentPipeVariants'].pipes_images[
          this.$store.state.organ.selectedPipe - 1
        ].url
      ) {
        return this.$store.getters['organ/currentPipeVariants'].pipes_images[
          this.$store.state.organ.selectedPipe - 1
        ];
      } else if (this.$store.state.global.settings.pipe_image) {
        return this.$store.state.global.settings.pipe_image;
      } else {
        return null;
      }
    },
  },
  methods: {
    changeData(e) {
      this.changedData = e;
    },
    prevStep() {
      if (this.organState.currentStep === 1) {
        this.$router.go(-1);
      } else {
        this.$store.commit(
          'organ/setCurrentStep',
          this.organState.currentStep - 1
        );
      }
    },
    closeSidebar() {
      this.$store.commit('organ/setFormStepsIsShow', false);
      this.$store.commit('organ/setFormCurrentStep', 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';

.pipe-image {
  height: 200px;
  background-color: #fff;
  border-radius: 25px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 80%;
    height: 80%;
    object-fit: contain;
    object-position: center center;
  }
}
</style>
