<template lang="html">
  <div class="organ-parts-desktop dungeon-map dungeon-map--desktop">
    <Back
      v-if="globalSettings"
      color="#0062ae"
      :label="globalSettings['button_names']['back_button']"
    />
    <div class="row dungeon-map__row gray-background">
      <div class="left">
        <div class="dungeon-map__pan-zoom">
          <div class="pipes-map-container">
            <OrganPipesMap />
          </div>
        </div>
      </div>

      <div class="right">
        <Steps
          :steps="organState.steps"
          :currentStep="organState.currentStep"
        />
        <div class="steps__heading">
          <Heading :hType="3" text="Orgelpfeifenauswahl" textAlign="center" />
          <Heading :hType="1" text="Orgelpfeife" textAlign="center" />
        </div>
        <SelectSlider
          title="Werk:"
          :options="$store.getters['organ/currentPipeVariants'].areas"
          :defaultOption="$store.state.organ.selectedPipe"
          :hideArrows="true"
        />
        <SelectSlider
          title="Pfeife:"
          :options="$store.getters['organ/currentPipeVariants'].pipes"
          :defaultOption="$store.state.organ.selectedPipe"
          :counter="true"
          :hideArrows="true"
        />
        <div class="price">
          {{
            $store.getters['organ/currentPipeVariants'].prices[
              $store.state.organ.selectedPipe - 1
            ]
          }}
          €
        </div>
        <div class="buttons-wrapper">
          <Button
            styleButton="border"
            @click="$store.commit('organ/showPipeVariantsScreen', false)"
          >
            <span>Zurück</span>
          </Button>
          <Button backgroundColor="#C99D66" @click="nextStep">
            <span v-if="globalSettings"> Nächster Schritt </span>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectSlider from '@/components/SelectSlider/SelectSlider';
import Button from '@/components/Button/Button';
import Heading from '@/components/Heading/Heading';
import Back from '@/components/Back/Back';
import Steps from '@/components/Steps/Steps';
import OrganPipesMap from '@/sections/Organ/OrganPipesMap.vue';

export default {
  name: 'OrganPartsDesktop',
  components: {
    OrganPipesMap,
    Button,
    SelectSlider,
    Heading,
    Back,
    Steps,
  },
  computed: {
    organState() {
      return this.$store.state.organ;
    },
    globalSettings() {
      return this.$store.state.global.settings;
    },
  },
  methods: {
    nextStep() {
      // this.$store.commit('organ/setCurrentStep', 2);
      this.$store.commit('organ/setFormStepsIsShow', true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';

.pipes-map-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.buttons-wrapper {
  display: flex;
  gap: 22px;

  :deep() {
    .button--border {
      background-color: transparent;
    }
  }
}

:deep() {
  .modal-window__container {
    width: 550px;
    max-width: 90%;
    border: none !important;
    padding: 28px;
    border-radius: 25px;
  }

  .wall-info {
    width: 100%;
  }

  .button {
    width: 160px;
    margin: 0 auto;
  }

  .wall-info__image {
    margin: 0 !important;
    margin-bottom: 30px !important;
    img {
      display: block;
      border-radius: 12.5px;
    }
  }

  .wall-info__heading {
    text-align: center;
    font-size: 30px;
    color: #c99d66;
    margin-bottom: 25px;
    line-height: 1.2;
  }

  .wall-info__description {
    margin-top: 0 !important;
    margin-bottom: 30px;
  }

  .wall-info__button {
    margin-top: 0;
  }
}
.price {
  text-align: center;
  font-size: 30px;
  color: #fff;
  margin-top: 50px;
}
</style>
