<template lang="html">
  <div class="stones-form__step-content">
    <FormCheckout
      button-label="Zurück zur Startseite"
      @checkoutNextStep="nextStep"
    />
  </div>
</template>

<script>
import FormCheckout from '@/components/Form/FormCheckout.vue';

export default {
  name: 'OrganFormStep3',
  components: {
    FormCheckout,
  },
  methods: {
    nextStep() {
      // this.$store.commit('organ/setFormStepsIsShow', false);
      // this.$store.commit('organ/setFormCurrentStep', 3);
      window.location.href = '/';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
