<template lang="html">
  <div class="stones-form__step-content">
    <FormData
      type-form="organ"
      @submitForm="submitForm"
      @cancelSubmit="cancelSubmit"
    />
  </div>
</template>

<script>
import FormData from '@/components/Form/FormData.vue';

export default {
  name: 'OrganFormStep1',
  components: {
    FormData,
  },
  methods: {
    submitForm() {
      // console.log(e);
      this.$store.commit('organ/setFormCurrentStep', 2);
    },
    cancelSubmit() {
      this.$store.commit('organ/setFormStepsIsShow', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
